/* You can add global styles to this file, and also import other style files */
@import 'https://library-sdb.apps.bancolombia.com/sufi/6.17.4/sufi.min.css';


:root {
    --sufi-rojo: #e31721;
    --sufi-gris-oscuro: #3f3c4d;
}
/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

// Para arreglar el icono el el modal de Galatea
p {
    margin-bottom: 0rem;
}

// Para corregir espaciado en opciones de input select
ol, ul {
    padding-left: 0rem;
}


// Fuentes
@font-face{
    font-family: CIBFont Sans;
    src: url(assets/fonts/CIBFontSans-Bold.woff) format("woff"), url(assets/fonts/CIBFontSans-Bold.otf) format("opentype"), url(assets/fonts/CIBFontSans-Bold.ttf) format("truetype");
}

@font-face{
    font-family: Open Sans;
    src: url(assets/fonts/OpenSans-Regular.ttf);
}
